import { h, resolveComponent } from 'vue'
import { createRouter, createMemoryHistory } from 'vue-router'
import { mapState } from 'vuex'
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '../store'
const routes = [
  {
    path: '/auth',
    name: 'Login',
    component: () => import('@/views/base/Login.vue'),
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/organization',
        name: 'Organizations',
        component: () => import('@/views/base/Organizations.vue'),
      },
      {
        path: '/:organizationKey',
        name: 'Projects',
        component: () => import('@/views/base/Projects.vue'),
      },
      {
        path: '/:organizationKey/:projectKey',
        name: 'Applications',
        component: () => import('@/views/base/Application.vue'),
      },
      // {
      //   path: '/:organizationKey/:projectName/:ApplicationName',
      //   name: 'Application',
      //   component: () => import('@/views/base/Single.vue'),
      // },
      {
        path: '/:organizationKey/:projectKey/:applicationKey',
        name: 'Entity',
        component: () => import('@/views/base/EntityComponent.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Entity&Component',
        name: 'Component',
        component: () => import('@/views/base/EntityComponent.vue'),
      },
      {
        path: '/:projectKey/:applicationKey/:children/:componentName', // Threats for Entity and component
        name: 'Threats',
        component: () => import('@/views/base/ComponentThreat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Security&Requirement',
        name: 'Security & Requirements',
        component: () => import('@/views/base/SecurityRequirement.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Feature',
        name: 'Features',
        component: () => import('@/views/base/Feature.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Boundary',
        name: 'Boundaries',
        component: () => import('@/views/base/Boundary.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Threat',
        name: 'Threat',
        component: () => import('@/views/base/Threat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/:componentKey/Threats',
        name: 'Threats',
        component: () => import('@/views/base/Threat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/:storyKey/Story-Threats',
        name: 'StoryThreats',
        component: () => import('@/views/base/StoryThreat.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/CounterMeasure',
        name: 'Counter Measures',
        component: () => import('@/views/base/counterMeasure.vue'),
      },
      {
        path: '/:organizationKey/:projectKey/:applicationKey/Vulnerability',
        name: 'Vulnerabilities',
        component: () => import('@/views/base/vulnerability.vue'),
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  mode: 'history',
  history: createMemoryHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  computed: {
    ...mapState(['isAuthenticated']),
  },
})
router.beforeEach((to, from, next) => {
  // if (to.path === from.path && to.hash !== from.hash) {
  //   next('/')
  // } else {
  //   next()
  // }
  if (to.path === '/') {
    if (store.state.isAuthenticated) {
      next()
    } else {
      next('/auth')
    }
  } else if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.state.isAuthenticated) {
      next()
    } else {
      next('/auth')
    }
  } else {
    next()
  }
})

export default router
