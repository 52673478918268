import { createStore } from 'vuex'
import axios from 'axios'
// import { fetchSecurityRequirements } from '@/api/apiService'
export default createStore({
  state: {
    sidebarVisible: false,
    sidebarUnfoldable: false,
    sidebarShow: true,
    organizations: [],
    projects: [],
    applications: [],
    components: [],
    securityRequirements: [],
    stories: [],
    boundaries: [],
    threats: [],
    connectedComponent: [],
    total_pages: 1,
    itemsPerPage: 10,
    currentPage: 1,
    searchKey: '',
    orgKey: '',
    proKey: '',
    appKey: '',
    boundaryKey: '',
    comKey: '',
    storyKey: '',
    threats_scenario_count: 0,
    total_docs: 0,
    isLoading: false,
    orgName: '',
    proName: '',
    appName: '',
    comName: '',
    storyName: '',
    srcount: 0,
    originalComponentName: '',
    originalComponentKey: '',
    storyThreatState: [],
    storySecurityExpectationState: [],
    isCreateButtonDisabled: false,
    isAuthenticated: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    offSidebar(state) {
      state.sidebarVisible = false
    },
    onSidebar(state) {
      state.sidebarVisible = true
    },
    // organization CRUD using vuex
    setPages(state, total_pages) {
      state.total_pages = total_pages
    },
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage
    },
    setItemsPerPage(state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage
    },
    setSearchKey(state, searchKey) {
      state.searchKey = searchKey
    },
    setCount(state, threats_scenario_count) {
      state.threats_scenario_count = threats_scenario_count
    },
    setTotalDocs(state, total_docs) {
      state.total_docs = total_docs
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setOrgName(state, orgName) {
      state.orgName = orgName
    },
    setProName(state, proName) {
      state.proName = proName
    },
    setAppName(state, appName) {
      state.appName = appName
    },
    setComName(state, comName) {
      state.comName = comName
    },
    setStoryName(state, storyName) {
      state.storyName = storyName
    },
    setConnects(state, comName) {
      state.connectedComponent.push(comName)
    },
    removeConnects(state, comName) {
      state.connectedComponent.pop(comName)
    },
    addOrganization(state, newOrganization) {
      state.organizations.push(newOrganization)
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations
    },
    setOriginalName(state, newName) {
      state.originalComponentName = newName
    },
    setOriginalKey(state, newKey) {
      state.originalComponentKey = newKey
    },
    setCreateButton(state, disableState) {
      state.isCreateButtonDisabled = disableState
    },
    setAuthenticate(state, authState) {
      state.isAuthenticated = authState
    },
    updateOrganization(state, updatedOrganization) {
      const index = state.organizations.findIndex(
        (org) => org._key === updatedOrganization._key,
      )
      if (index !== -1) {
        state.organizations[index] = updatedOrganization
      }
    },
    removeOrganization(state, organizationKey) {
      for (let i = state.organizations.length - 1; i >= 0; i--) {
        if (state.organizations[i]._key === organizationKey) {
          state.organizations.splice(i, 1)
        }
      }
    },
    // project CRUD
    addProject(state, newProject) {
      state.projects.push(newProject)
    },
    setProjects(state, projects) {
      state.projects = projects
    },
    setOrgKey(state, orgKey) {
      state.orgKey = orgKey
    },
    setProKey(state, proKey) {
      state.proKey = proKey
    },
    updateProject(state, updatedProject) {
      const index = state.projects.findIndex(
        (project) => project._key === updatedProject._key,
      )
      if (index !== -1) {
        state.projects[index] = updatedProject
      }
    },
    removeProject(state, projectKey) {
      for (let i = state.projects.length - 1; i >= 0; i--) {
        if (state.projects[i]._key === projectKey) {
          state.projects.splice(i, 1)
        }
      }
    },
    // application CRUD
    addApplication(state, newApplication) {
      state.applications.push(newApplication)
    },
    setApplications(state, applications) {
      state.applications = applications
    },
    setAppKey(state, appKey) {
      state.appKey = appKey
    },
    updateApplication(state, updatedApplication) {
      const index = state.applications.findIndex(
        (application) => application._key === updatedApplication._key,
      )
      if (index !== -1) {
        state.applications[index] = updatedApplication
      }
    },
    updateSecurityRequirements(state, updateSR) {
      const index = state.securityRequirements.findIndex(
        (sr) => sr._key === updateSR._key,
      )
      if (index !== -1) {
        state.securityRequirements[index] = updateSR
      }
    },
    removeApplication(state, applicationKey) {
      for (let i = state.applications.length - 1; i >= 0; i--) {
        if (state.applications[i]._key === applicationKey) {
          state.applications.splice(i, 1)
        }
      }
    },
    setComponents(state, components) {
      state.components = components
    },
    addComponent(state, newComponent) {
      state.components.push(newComponent)
    },
    setComKey(state, comKey) {
      state.comKey = comKey
    },
    setStoryKey(state, storyKey) {
      state.storyKey = storyKey
    },
    setSecurityRequirements(state, securityRequirements) {
      state.securityRequirements = securityRequirements
    },
    removeSR(state, srKey) {
      for (let i = state.securityRequirements.length - 1; i >= 0; i--) {
        if (state.securityRequirements[i]._key === srKey) {
          state.securityRequirements.splice(i, 1)
        }
      }
    },
    deleteBoundary(state, boundaryKey) {
      for (let i = state.boundaries.length - 1; i >= 0; i--) {
        if (state.boundaries[i]._key === boundaryKey) {
          state.boundaries.splice(i, 1)
        }
      }
    },
    deleteThreat(state, threatKey) {
      for (let i = state.threats.length - 1; i >= 0; i--) {
        if (state.threats[i]._key === threatKey) {
          state.threats.splice(i, 1)
        }
      }
    },
    setStories(state, stories) {
      state.stories = stories
    },
    addStory(state, story) {
      state.stories.push(story)
    },
    updateStory(state, updatedStory) {
      const index = state.stories.findIndex(
        (story) => story._key === updatedStory._key,
      )
      if (index !== -1) {
        state.stories[index] = updatedStory
      }
    },
    setBoundaries(state, boundaries) {
      state.boundaries = boundaries
    },
    setThreats(state, threats) {
      state.threats = threats
    },
    updateBoundary(state, updatedBoundary) {
      const index = state.boundaries.findIndex(
        (boundary) => boundary._key === updatedBoundary._key,
      )
      if (index !== -1) {
        state.boundaries[index] = updatedBoundary
      }
    },
    updateThreat(state, updatedThreat) {
      const index = state.threats.findIndex(
        (boundary) => boundary._key === updatedThreat._key,
      )
      if (index !== -1) {
        state.threats[index] = updatedThreat
      }
    },
    addBoundary(state, boundary) {
      state.boundaries.push(boundary)
    },
    setSRCount(state, srCount) {
      state.srcount = srCount
    },
  },
  actions: {
    async addOrganization({ commit }, newOrganizationData) {
      try {
        const response = await axios.put(
          'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/',
          newOrganizationData,
        )
        commit('addOrganization', response.data.data)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/?page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setOrganizations', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setTotalDocs', response.data.total_docs)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/?page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setOrganizations', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setTotalDocs', response.data.total_docs)
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error adding organization:', error)
      }
    },
    async fetchOrganizations({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/?page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          commit('setOrganizations', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/?page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setOrganizations', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
        }
      } catch (error) {
        console.error('Error fetching organizations:', error)
      }
    },
    async updateOrganization({ commit }, { key, editedData }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/${key}`,
          editedData,
        )
        commit('updateOrganization', response.data.data)
      } catch (error) {
        console.error('Error updating organization:', error)
      }
    },
    async removeOrganization({ commit }, organizationKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/${organizationKey}`,
        )
        commit('removeOrganization', organizationKey)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/?page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setOrganizations', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/organization/?page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setOrganizations', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error removing organization:', error)
      }
    },
    async fetchProject({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          commit('setProjects', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setItemsPerPage', response.data.size)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${state.orgKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setProjects', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setItemsPerPage', response.data.size)
          commit('setTotalDocs', response.data.total_docs)
        }
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    },
    async addProject({ commit }, newProject) {
      try {
        const response = await axios.put(
          'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/',
          newProject,
        )
        commit('addProject', response.data.data)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${this.state.orgKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setProjects', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setItemsPerPage', response.data.size)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${this.state.orgKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setProjects', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setTotalDocs', response.data.total_docs)
          commit('setIsLoading', false)
          commit('setItemsPerPage', response.data.size)
        }
      } catch (error) {
        console.error('Error adding project:', error)
      }
    },
    async editProject({ commit }, { key, editedData }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/${key}`,
          editedData,
        )
        commit('setIsLoading', false)
        commit('updateProject', response.data.data)
      } catch (error) {
        console.error('Error updating project:', error)
      }
    },
    async removeProject({ commit }, projectKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/${projectKey}`,
        )
        commit('removeProject', projectKey)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${this.state.orgKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setProjects', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setItemsPerPage', response.data.size)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/project/?org=${this.state.orgKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setProjects', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
          commit('setTotalDocs', response.data.total_docs)
          commit('setItemsPerPage', response.data.size)
        }
      } catch (error) {
        console.error('Error removing project:', error)
      }
    },
    // application side
    async fetchApplication({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${state.proKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          console.log(response.data.data)
          commit('setApplications', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setItemsPerPage', response.data.size)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${state.proKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setApplications', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setItemsPerPage', response.data.size)
          commit('setTotalDocs', response.data.total_docs)
          alert(state.total_docs)
        }
      } catch (error) {
        console.error('Error fetching application:', error)
      }
    },
    async addApplication({ commit }, newApplication) {
      try {
        const response = await axios.put(
          'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/',
          newApplication,
        )
        commit('addApplication', response.data.data)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${this.state.proKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setApplications', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setItemsPerPage', response.data.size)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${this.state.proKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setApplications', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setItemsPerPage', response.data.size)
        }
      } catch (error) {
        console.error('Error adding application:', error)
      }
    },
    async editApplication({ commit }, { key, editedData }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/${key}`,
          editedData,
        )
        commit('setIsLoading', false)
        commit('updateApplication', response.data.data)
      } catch (error) {
        commit('setIsLoading', false)
        console.error('Error updating application:', error)
      }
    },
    async removeApplication({ commit }, applicationKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/${applicationKey}`,
        )
        commit('removeApplication', applicationKey)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${this.state.proKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setApplications', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setTotalDocs', response.data.total_docs)
          commit('setIsLoading', false)
          commit('setItemsPerPage', response.data.size)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${this.state.proKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setApplications', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setItemsPerPage', response.data.size)
        }
      } catch (error) {
        console.error('Error removing application:', error)
      }
    },
    // component part
    async fetchComponent({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          console.log(response.data.data)
          commit('setComponents', response.data.data)
          commit('setIsLoading', false)
          commit('setCount', response.data.threats_scenario_count)
          // commit('setTotalDocs', response.data.total_docs)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setIsLoading', false)
          commit('setComponents', response.data.data)
        }
      } catch (error) {
        console.error('Error fetching Components:', error)
      }
    },
    async addComponent({ commit }, newComponent) {
      try {
        const response = await axios.put(
          'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/',
          newComponent,
        )
        commit('setIsLoading', false)
        commit('addComponent', response.data.data)
        const response1 = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${this.state.proKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
        )
        commit('setIsLoading', false)
        commit('setComponents', response1.data.data)
      } catch (error) {
        console.error('Error adding Component:', error)
      }
    },
    async addComponentConnection(connectInfo) {
      try {
        await axios.post(
          'https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/connect',
          connectInfo,
        )
        // commit('setIsLoading', false)
        // commit('addComponent', response.data.data)
        // const response1 = await axios.get(
        //   `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/application/?project=${this.state.proKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
        // )
        // commit('setComponents', response1.data.data)
      } catch (error) {
        console.error('Error adding Component Connection:', error)
      }
    },
    async removeComponent({ commit }, componentKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/${componentKey}`,
        )
        commit('setIsLoading', false)
        commit('removeComponent', componentKey)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setIsLoading', false)
          commit('setComponents', response.data.data)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setIsLoading', false)
          commit('setComponents', response.data.data)
        }
      } catch (error) {
        console.error('Error removing component:', error)
      }
    },
    // Security Requirements part
    async fetchSecurityRequirements({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          commit('setTotalDocs', response.data.total_docs)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setSecurityRequirements', response.data.data)
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setSecurityRequirements', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error fetching security requirement:', error)
      }
    },
    async editSecurityRequirements({ commit }, { key, updatedData }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/${key}`,
          updatedData,
        )
        if (response.data.success == 'true') alert('Successfully updated!')
        commit('setIsLoading', false)
        commit('updateSecurityRequirements', response.data.data)
      } catch (error) {
        commit('setIsLoading', false)
        console.error('Error updating security requirement:', error)
      }
    },
    async createSecurityRequirements({ state }) {
      try {
        const response = await axios.post(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/generate/${state.appKey}`,
        )
        alert(response.data.message)
        // commit('setIsLoading', false)
      } catch (error) {
        console.error('Error adding security requirement:', error)
      }
    },
    async removeSecurityRequirements({ commit }, srKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/${srKey}`,
        )
        commit('setIsLoading', false)
        commit('removeSR', srKey)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit('setSecurityRequirements', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-requirement/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit('setIsLoading', false)
          commit('setSecurityRequirements', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error removing security requirement:', error)
      }
    },
    async createStory({ commit }, newStory) {
      try {
        const response = await axios.put(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/`,
          newStory,
        )
        commit('addStory', response.data.data)
        commit('setTotalDocs', this.state.total_docs + 1)
      } catch (error) {
        console.error('Error adding story:', error)
      }
    },
    async fetchStory({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setTotalDocs', response.data.total_docs)
          commit('setStories', response.data.data)
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
          let tem_stories = state.stories
          for (let i = 0; i < tem_stories.length; i++) {
            const storyThreat = axios.get(
              `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${tem_stories[i]._key}?page=1&size=100`,
            )
            if ((await storyThreat).data.total_docs !== 0)
              state.storyThreatState[i] = true
            else state.storyThreatState[i] = false
            const storySecurityExpecation = axios.get(
              `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-expecation/?story_key=${tem_stories[i]._key}?page=1&size=100`,
            )
            if ((await storySecurityExpecation).data.total_docs !== 0)
              state.storySecurityExpectationState[i] = true
            else state.storySecurityExpectationState[i] = false
          }
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setStories', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
          commit('setTotalDocs', response.data.total_docs)
          let tem_stories = state.stories
          for (let i = 0; i < tem_stories.length; i++) {
            const storyThreat = axios.get(
              `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${tem_stories[i]._key}?page=1&size=100`,
            )
            if ((await storyThreat).data.total_docs !== 0)
              state.storyThreatState[i] = true
            else state.storyThreatState[i] = false
            const storySecurityExpecation = axios.get(
              `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/security-expecation/?story_key=${tem_stories[i]._key}?page=1&size=100`,
            )
            const temp_key = tem_stories[i]._key
            if ((await storySecurityExpecation).data.total_docs !== 0)
              state.storySecurityExpectationState[temp_key] = true
            else state.storySecurityExpectationState[temp_key] = false
          }
        }
      } catch (error) {
        console.error('Error fetching stories:', error)
      }
    },
    async createThreatsForStory({ commit }, key) {
      try {
        // const previous = await axios.get(
        //   `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${key}?page=${state.currentPage}&size=${state.itemsPerPage}`,
        // )
        // let numThreatsBefore = previous.data.total_docs
        const response = await axios.post(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/generate/${key}`,
        )
        if (response.data.error == false) {
          alert('Threats added to the user story to queue for AI generation.')
        } else {
          alert('There was an error while generating. Please try again!')
        }
        commit('setIsLoading', false)
      } catch (error) {
        console.error('Error adding story:', error)
      }
    },
    async updateStory({ commit }, { key, updateStoryContent }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/${key}`,
          updateStoryContent,
        )
        commit('updateStory', response.data.data)
        commit('setIsLoading', false)
      } catch (error) {
        console.error('Error updating story:', error)
      }
    },
    async removeStory({ commit }, storyKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/${storyKey}`,
        )
        commit('deleteStory', storyKey)
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setStories', response.data.data)
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
          commit('setTotalDocs', this.state.total_docs - 1)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/user-story/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setStories', response.data.data)
          commit('setIsLoading', false)
          commit('setTotalDocs', this.state.total_docs - 1)
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error removing story:', error)
      }
    },
    async createBoundary({ commit }, newBoundary) {
      try {
        await axios.put(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/boundary/`,
          newBoundary,
        )
        const response1 = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/boundary/?application_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
        )
        commit('setBoundaries', response1.data.data)
        commit(
          'setPages',
          response1.data.total_docs % 10 == 0 &&
            response1.data.total_docs / 10 >= 1
            ? response1.data.total_pages - 1
            : response1.data.total_pages,
        )
        commit('setIsLoading', false)
        commit('setCurrentPage', response1.data.page)
        commit('setTotalDocs', response1.data.total_docs)
      } catch (error) {
        console.error('Error adding boundary:', error)
      }
    },
    async fetchBoundary({ commit, state }) {
      try {
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/boundary/?application_key=${state.appKey}&page=${state.currentPage}&size=${state.itemsPerPage}`,
        )
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setTotalDocs', response.data.total_docs)
        commit('setBoundaries', response.data.data)
        commit('setIsLoading', false)
        commit('setCurrentPage', response.data.page)
      } catch (error) {
        console.error('Error fetching boundaries:', error)
      }
    },
    async updateBoundary({ commit }, { key, updateBoundaryContent }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/boundary/${key}`,
          updateBoundaryContent,
        )
        commit('updateBoundary', response.data.data)
        commit('setIsLoading', false)
      } catch (error) {
        console.error('Error updating boundary:', error)
      }
    },
    async removeBoundary({ commit }, boundaryKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/boundary/${boundaryKey}`,
        )
        commit('deleteBoundary', boundaryKey)
        const response = await axios.get(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/boundary/?app_key=${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
        )
        commit(
          'setPages',
          response.data.total_docs % 10 == 0 &&
            response.data.total_docs / 10 >= 1
            ? response.data.total_pages - 1
            : response.data.total_pages,
        )
        commit('setBoundaries', response.data.data)
        commit('setIsLoading', false)
        commit('setCurrentPage', response.data.page)
        commit('setTotalDocs', response.data.total_docs)
      } catch (error) {
        console.error('Error removing boundaries:', error)
      }
    },
    async generateThreats({ commit, state }, newPayload) {
      try {
        const response = await axios.post(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/generate`,
          newPayload,
        )
        if (response.data.success == 'true') {
          const response = axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/component/${state.comKey}?page=1&size=10`,
          )
          alert('Succesfully generated!')
          commit('setCount', response.data.threats_scenario_count)
          this.setIsLoading(false)
        }
      } catch (error) {
        console.error('Error adding boundary:', error)
      }
    },
    async fetchThreat({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/component/${state.comKey}?page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          commit('setThreats', response.data.data)
          commit('setIsLoading', false)
          commit('setCount', response.data.threats_scenario_count)
          commit('setTotalDocs', response.data.total_docs)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 ||
              state.itemsPerPage == response.data.total_docs
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/component/${state.comKey}?page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setIsLoading', false)
          commit('setThreats', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 ||
              state.itemsPerPage == response.data.total_docs
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setCount', response.data.threats_scenario_count)
          commit('setTotalDocs', response.data.total_docs)
        }
      } catch (error) {
        console.error('Error fetching threats:', error)
      }
    },
    async removeThreat({ commit }, threatKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/${threatKey}`,
        )
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setThreats', response.data.data)
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/${this.state.appKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setThreats', response.data.data)
          commit('setIsLoading', false)
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error removing story:', error)
      }
    },
    async updateThreat({ commit }, { key, updateThreatContent }) {
      try {
        const response = await axios.patch(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/${key}`,
          updateThreatContent,
        )
        commit('updateThreat', response.data.data)
        commit('setIsLoading', false)
      } catch (error) {
        console.error('Error updating Threat:', error)
      }
    },
    async connectComponent({ commit }, connectData) {
      try {
        const response = await axios.post(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/component/generate/connections`,
          connectData,
        )
        if (response.data.error == false) {
          alert('Successfully connected')
        }
        commit('setIsLoading', false)
      } catch (error) {
        console.error('Error connecting component:', error)
      }
    },
    async fetchStoryThreat({ commit, state }) {
      try {
        if (state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${state.storyKey}?page=${state.currentPage}&size=${state.itemsPerPage}`,
          )
          commit('setThreats', response.data.data)
          commit('setIsLoading', false)
          commit('setCount', response.data.threats_scenario_count)
          commit('setTotalDocs', response.data.total_docs)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${state.storyKey}?page=${state.currentPage}&size=${state.itemsPerPage}&query=${state.searchKey}`,
          )
          commit('setIsLoading', false)
          commit('setThreats', response.data.data)
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setCurrentPage', response.data.page)
          commit('setCount', response.data.threats_scenario_count)
          commit('setTotalDocs', response.data.total_docs)
        }
      } catch (error) {
        console.error('Error fetching threats for stories:', error)
      }
    },
    async removeStoryThreat({ commit }, threatKey) {
      try {
        await axios.delete(
          `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/${threatKey}`,
        )
        if (this.state.searchKey.length == 0) {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${this.state.storyKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setThreats', response.data.data)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setCurrentPage', response.data.page)
        } else {
          const response = await axios.get(
            `https://threatplaybook-pij5uo3ngq-uc.a.run.app/api/threat-scenario/story/${this.state.storyKey}&page=${this.state.currentPage}&size=${this.state.itemsPerPage}&query=${this.state.searchKey}`,
          )
          commit(
            'setPages',
            response.data.total_docs % 10 == 0 &&
              response.data.total_docs / 10 >= 1
              ? response.data.total_pages - 1
              : response.data.total_pages,
          )
          commit('setThreats', response.data.data)
          commit('setIsLoading', false)
          commit('setTotalDocs', response.data.total_docs)
          commit('setCurrentPage', response.data.page)
        }
      } catch (error) {
        console.error('Error removing threats for stories:', error)
      }
    },
  },
})
